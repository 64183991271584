import { graphql } from 'gatsby'
import AgentDetails from '../components/AgentDetails'

export default AgentDetails

export const query = graphql`
  query agentQuery($slug: String!) {
    contentfulPropertyAgent(slug: { eq: $slug }) {
      ...PropertyAgent
      propertyList: property {
        ...Property
      }
    }
  }
`
