import React from 'react'
import {Link} from 'gatsby'
import Layout from '../LayoutView'
import PageHeader from '../common/pageHeader'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import AgentInfo from './agentInfo'
import CardFeature from '../common/CardFeature'
import { BreadcrumbJsonLd, GatsbySeo } from 'gatsby-plugin-next-seo'
import Breadcrumb from "../PropertyDetails/breadcrumb"

const AgentDetails = ({ data }) => {
  const { contentfulPropertyAgent } = data
  const title = `${contentfulPropertyAgent.name}`
  const description = `${contentfulPropertyAgent.name}`
  const url = `${contentfulPropertyAgent.slug}`
  const items = [
    { to: '/', label: 'Home' },
    {to:'/real-estate-companies', label: 'Real Estate Companies'},
    {label: title }
  ]
  return (
    <Layout>
      <GatsbySeo
        title={title}
        description={description}
        openGraph={{
          title: { title },
          description: {description},
          url: {url},
          images: [
            {
              url: 'https://images.ctfassets.net/abyiu1tn7a0f/2GruamZHfQ3hWKijmEHeEA/689dc4a2fcb59da90cbbc27e54f914dc/the-signature-terraces-and-homes-and-apartments-abijo-for-sale.jpg?h=250',
              width: 850,
              height: 650,
              alt: 'ownahome.ng picture'
            }
          ],
          site_name: 'Owning a home does not need to make you lose sleep'
        }}
        twitter={{
          handle: '@ownahome_ng',
          site: '@ownahome.ng',
          cardType: 'summary_large_image'
        }}
      />
      <BreadcrumbJsonLd
      itemListElements={[
        {
          position: 1,
          name: 'Home',
          item: '/',
        },
        {
          position: 2,
          name: 'Real Estate Companies',
          item: `/real-estate-companies`
        },
        {
          position: 3,
          name: {title},
          item: `/${url}`
        },
      ]}
    />
      <PageHeader>
        <div className='d-sm-flex items-center'>
          {contentfulPropertyAgent.logo && (
            <div className='me-3' style={{ width: '150px', height: '70px' }}>
              <Img image={getImage(contentfulPropertyAgent.logo)} alt='logo' />
            </div>
          )}
          <h1 className='h3 text-white'>{contentfulPropertyAgent.name}</h1>
        </div>
      </PageHeader>
      <div className='pt-5 pd-bottom-70'>
      <div className='mx-5'>
      <Breadcrumb>
              {items.map(({ to, label }) => (
                <Link key={to} to={to}>
                  {label}
                </Link>
              ))}
            </Breadcrumb>

        </div>
            
        <div className='row mb-4'>
        
          
          {contentfulPropertyAgent.about && (
            <div
              className='col-9 offset-md-3'
              dangerouslySetInnerHTML={{
                __html: contentfulPropertyAgent.about.childMarkdownRemark.html
              }}
            />
          )}
        </div>

        <div className='row container-fluid  mx-auto'>
          <div className='col-xl-3 col-lg-4 sitebar mb-4'>
            <AgentInfo {...contentfulPropertyAgent} />
          </div>
          <div className='col'>
            <div className='my-2 p-3 mb-2 bg-secondary'>
              <h6 className=' text-white mb-0'>Agent property list</h6>
            </div>
            <div className='row'>
              {contentfulPropertyAgent?.propertyList.length ? (
                contentfulPropertyAgent.propertyList.map(item => (
                  <div key={item.id} className='col-md-4 col-12'>
                    <CardFeature {...item} />
                  </div>
                ))
              ) : (
                <h6>No results</h6>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AgentDetails
