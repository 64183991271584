import React from 'react'
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa'
import { BreadcrumbJsonLd, GatsbySeo } from 'gatsby-plugin-next-seo'

const AgentInfo = ({ address, email, phoneNumber }) => {
  return (
    <div className='contact-info card p-2'>
      <h6 className='mb-3'>Contact Info</h6>
      <div className='media d-flex'>
        <div className='media-left me-2'>
          <FaMapMarkerAlt />
        </div>
        <div className='media-body'>
          <p className='mb-0'>Address</p>
          <span
            dangerouslySetInnerHTML={{
              __html: address.childMarkdownRemark.html
            }}
          />
          <span>{address.address}</span>
        </div>
      </div>
      <div className='media d-flex'>
        <div className='media-left me-2'>
          <FaPhoneAlt />
        </div>
        <div className='media-body'>
          <p className='mb-0'>Phone</p>
          {/*<span>{phoneNumber.join(', ')}</span>*/}
          <span>
                  <a
                    href='tel: +2348101536132'
                    style={{ textDecoration: 'none', color: 'blue' }}
                  >
                    +2348101536132
                  </a>
                </span>
        </div>
      </div>
      <div className='media d-flex mb-0'>
        <div className='media-left me-2'>
          <FaEnvelope />
        </div>
        <div className='media-body'>
          <p className='mb-0'>Email</p>
          <span>{email}</span>
        </div>
      </div>
    </div>
  )
}

export default AgentInfo
